<template>
  <div class="contactUs">
    <div class="content_area">
      <div class="title"><span>//</span>联系我们</div>
      <div class="main">
        <div>
          青岛市国际物流商会，是物流行业非营利性的社会公益组织，是由青岛本地国际物流界的优秀企业、个人，及相关单位自愿组成的自律性社会团体。本商会旨在代表物流业界的共同利益，建立政府与企业之间的信息交流桥梁与纽带，维护行业的切身权益，加强同业联系，建立互信合作，促进资源共享，和谐共赢，谋求永续发展，保持良性竞争，提高业界企业的经营能力，优化服务品质与专业素养，参与国家“一带一路”的建设与“链接”。为国际、国内的物流企业在全方位发展的经营活动中提供一个更为广阔与和谐的交流平台和发展空间，力争为物流企业提供优质高效的“物流指数”与保障服务，协助物流企业寻找新机遇、打开新格局，从而为青岛物流人搭建一个和谐、稳定的家园！
        </div>
		<div class="adress" style="margin-top: 30px;">
			<mapPage :longitude="longitude" :dimension="dimension"></mapPage>
		 </div>
      </div>
    </div>
    <div class="board">
      <div class="content">
        <div class="tc title">
          如果您在使用本网站时有任何意见或建议，请写下您的意见或者建议。我们会尽快答复您，感谢您的关注~
        </div>
        <div class="evaluate">
          <el-form
            :model="evaluateVal"
            ref="evaluateForm"
            :rules="evaluateRules"
          >
            <div class="clearfix">
              <el-form-item prop="name" class="fl">
                <el-input
                  size="small"
                  class="input"
                  v-model="evaluateVal.name"
                  type="email"
                  placeholder="名字"
              /></el-form-item>
              <el-form-item prop="email" class="fl">
                <el-input
                  size="small"
                  class="input1"
                  v-model="evaluateVal.email"
                  placeholder="邮箱"
              /></el-form-item>
              <el-form-item prop="phone" class="fl">
                <el-input
                  size="small"
                  class="input1"
                  type="number"
                  v-model="evaluateVal.phone"
                  placeholder="电话"
              /></el-form-item>
            </div>
            <el-form-item prop="textarea">
              <el-input
                type="textarea"
                class="textarea"
                placeholder="请写下您想说的话"
                v-model="evaluateVal.content"
            /></el-form-item>
            <div class="tc">
              <el-button
                plain
                size="small"
                class="submit"
                @click="submit"
                :loading="btnLoading"
                >提交</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { validateTelephone, validateEmail } from "~/baseUtils/validate";
import mapPage from "~/baseComponents/map";
export default {
  components: { mapPage },
  metaInfo: {
    title: "联系我们",
  },
  data() {
    return {
      evaluateVal: {
        name: "",
        email: "",
        phone: "",
        content: "",
      },
      longitude: 120.391337,  //,
      dimension: 36.071299,
      btnLoading: false,
      evaluateRules: {
        phone: {
          validator: async (rule, value, callback) => {
            if (!validateTelephone(value)) {
              this.codeBtnDisabled = true;
              callback("手机号格式错误");
            } else {
            }
          },
          trigger: "blur",
        },
        email: {
          validator: async (rule, value, callback) => {
            if (!validateEmail(value)) {
              this.codeBtnDisabled = true;
              callback("邮箱格式错误");
            } else {
            }
          },
          trigger: "blur",
        },
      },
    };
  },
  methods: {
    async submit() {
      if(!this.IS_LOGIN){
         this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
         return;
      }
      if (!this.evaluateVal.content) {
        this.$message.warning("输入建议不能为空");
        return;
      }
      this.btnLoading = true;
      this.evaluateVal.source=this.PJSource
      let res = await this.$store.dispatch(
        "API_index/suggestionSave",
        this.evaluateVal
      );
      this.btnLoading = false;
      if (res.success) {
        this.$message.success("提交成功");
        this.evaluateVal = {};
      } else {
      }
    },
  },
  mounted() {
  },
};
</script>
<style lang="less" scoped>
.contactUs {
  min-height: 800px;
  background: #ffffff;
  .content_area {
    padding: 30px 0 50px;
    .title {
      font-size: 24px;
      span {
        color: #3384b1;
        margin-right: 10px;
      }
    }
    .main {
      margin: 24px 0;
      div {
        text-indent: 2em;
        line-height: 25px;
      }
    }
    .adress {
      height: 210px;
      // border: 1px solid #ff5155;
    }
  }
  .board {
    width: 100%;
    height: 412px;
    background: #3384b1;
    .content {
      width: 800px;
      margin: 0 auto;
      padding: 36px 0;
      .title {
        color: #fff;
      }
      .evaluate {
        margin-top: 36px;
        .input {
          width: 200px;
        }
        .input1 {
          width: 280px;
          margin-left: 16px;
        }

        .textarea {
          margin-top: 24px;
          min-height: 154px;
        }
        .submit {
          width: 115px;
          margin-top: 8px;
          color: #3384b1;
          &:hover{
            border-color: #3384b1;
          }
        }
      }
    }
  }
  /deep/.el-textarea__inner {
    min-height: 154px !important;
  }
  /deep/.el-form-item__error {
    color: #d8563a;
    margin-left: 16px;
  }
}
</style>